<template lang="html">
  <section class="co-descargables" v-if="folletos.length>0">
    <h2 class="text-left mb-5">Descargar información relacionada</h2>
    <Carousel
      :value="folletos"
      :responsiveOptions="responsiveCarousel"
      :numVisible="folletos.length > 3 ? 3 : folletos.length"
      :circular="folletos.length > 3"
      :autoplayInterval="15000"
      indicatorsContentClass=""
      containerClass=""
    >
      <template #item="folleto">
        <a
          class="folleto"
          @click="toggleOP($event, folleto.data)"
          v-tooltip.right="folleto.data.xnombre"
        >
          <img class="folleto-img" :src="folleto.data.ximg" />
        </a>
      </template>
    </Carousel>
    <OverlayPanel
      ref="folletoOP"
      appendTo="body"
      :showCloseIcon="true"
      id="overlay_panel"
      style="width: 360px"
    >
      <div class="folleto-op">
        <p>
          Sólo tendrás que introducir estos datos la primera vez que descargas
        </p>
        <form class="w-100" @submit="descargarFolleto">
          <span class=" p-input-icon-left p-field mt-4 col-12">
            <i class="pi pi-user ml-1 mr-1"></i>
            <InputText
              id="nombre"
              name="nombre"
              type="text"
              v-model="form.nombre"
              placeholder="Nombre"
              :class="{ 'p-invalid': !isNomValid, 'w-100': true }"
              @focusout="validarNombre"
              required
            />
            <label for="nombre" class="d-none">Nombre</label>
          </span>
          <span class="p-input-icon-left p-float-label col-12">
            <i class="pi pi-sitemap ml-1 mr-1"></i>
            <InputText
              id="apellido"
              name="apellido"
              type="text"
              v-model="form.compania"
              placeholder="Compañía"
              :class="{ 'p-invalid': !isCompValid, 'w-100': true }"
              @focusout="validarCompania"
              required
            />
            <label for="apellido" class="d-none">Compañía</label>
          </span>
          <span class=" p-input-icon-left p-float-label col-12">
            <i class="far fa-envelope ml-1 mr-1"></i>
            <InputText
              id="email"
              name="email"
              type="mail"
              v-model="form.email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              placeholder="Email"
              :class="{ 'p-invalid': !isEmailValid, 'w-100': true }"
              @focusout="validarEmail"
              required
            />
            <label for="email" class="d-none">Email</label>
          </span>
        </form>
        <Button
          @click="descargarFolleto"
          label="Descargar"
          class="w-100 mt-1 p-button-outlined"
        />
      </div>
    </OverlayPanel>
  </section>
</template>

<script lang="js">
import DescargablesService from '../../services/descargables.service';
import Cookies from 'js-cookie'
import API_URL from '../../backend.js'
  export default  {
    name: 'co-descargables',
    props: [],
    mounted () {
      let enlace = this.$route.params.enlace;
      if (enlace == "servicios") {
      DescargablesService.getFolletosInfoServicios(this.$route.params.id).then((response) => {
        this.folletos = response.data
        this.folletos.forEach((folleto)=>{
          folleto.ximg = API_URL+folleto.ximg
          folleto.xdireccion = API_URL+folleto.xdireccion
        });
      });
      }else if(enlace == "perfiles"){
        DescargablesService.getFolletosInfoPerfiles(this.$route.params.id).then((response) => {
        this.folletos = response.data;
      });
      }
    },
    data () {
      return {
        form:{
          nombre:'',
          compania:'',
          email:''
        },
        folletoDescargar:{},
        isNomValid:true,
        isCompValid:true,
        isEmailValid:true,
        folletos:[],
        responsiveCarousel:[
          {
            breakpoint:"576px",
            numVisible:1,
            numScroll:2

          },
          {
            breakpoint:"768px",
            numVisible:1,
            numScroll:2
          },
          {
            breakpoint:"1280px",
            numVisible:2,
            numScroll:2
          }
        ]
      }
    },
    methods: {
      toggleOP(event, folleto){
        let datosDescargables = Cookies.get("descargableData");
        if(datosDescargables) datosDescargables = JSON.parse(datosDescargables);
        if(datosDescargables && datosDescargables.nombre && datosDescargables.email && datosDescargables.compania){
          this.folletoDescargar = folleto;
          this.download()
          return;
        }
        this.$refs.folletoOP.toggle(event);
        this.folletoDescargar = folleto;
      },
      validarNombre(){
        if(this.form.nombre.length <= 1 || this.form.nombre.length >60){
          this.isNomValid = false;
        }else {this.isNomValid = true;}
      },
      validarCompania(){
        if(this.form.compania.length <= 1 || this.form.compania.length >60){
          this.isCompValid = false;
        }else {this.isCompValid = true;}
      },
      validarEmail(){
        if(!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.form.email)){
          this.isEmailValid = false;
        }else {this.isEmailValid = true;}
      },
      descargarFolleto(){
        if(this.isNomValid && this.isCompValid && this.isEmailValid){
          Cookies.set('descargableData', JSON.stringify(this.form))
          this.download()
        }
      },
      download(){
        DescargablesService.getDescargarFolleto(this.folletoDescargar).then((response)=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.folletoDescargar.xnombre+'.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
      }
    },
    computed: {

    }
}
</script>

<style lang="scss">
.co-descargables {
  .folleto {
    &:hover {
      cursor: pointer;
    }
    &-img {
      max-width: 200px;
    }
  }
}
.p-button.p-button-outlined {
  background-color: transparent !important;
  color: #f8b505;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: #f8b30517 !important;
  color: #f8b505;
  border: 1px solid;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #d99e04;
  color: #ffffff;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #f8b505;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: box-shadow 0.15s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
</style>
