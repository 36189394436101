var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"servicios-vue text-lg"},[_c('coHeroServicio',{staticClass:"p-2 p-md-0",attrs:{"servicio":_vm.servicio}}),_c('div',{staticClass:"servicios-wrapper align-items-start d-flex flex-wrap ml-2 md:mx-6 xl:mx-8 mt-5 pt-5 pb-5 mb-5"},[_c('div',{staticClass:"col-12 col-xl-4 servicios order-xl-1 order-2"},[_c('div',{staticClass:"py-5 px-5 servicios-menu pl-5"},[_c('h2',{staticClass:"mb-4"},[_vm._v("SERVICIOS")]),_vm._l((_vm.todosServicios),function(serv,index){return _c('span',{key:index + serv + index,staticClass:"py-3 px-2 servicios-menu-links text-md",on:{"click":function($event){return _vm.irA(serv.xto)}}},[_vm._v(_vm._s(serv.xtitulo)+"     ⇰")])}),_c('coConozca',{staticClass:"mt-4 -ml-5",staticStyle:{"margin-left":"-1.7rem","margin-right":"-1.7rem"},attrs:{"origen1":"/servicios","origen2":'/' + _vm.servicio.xservicioid}})],2)]),_c('ScrollPanel',{staticClass:"col-12 col-xl-8 lg:pl-5 d-flex flex-column order-xl-2 order-1 custombar2"},[_vm._l((_vm.bloquesSinCards),function(bloque){return _c('div',{key:bloque.xid,staticClass:"d-flex flex-column text-left mb-5"},[(
            bloque.xtipo == 'p' ||
            bloque.xtipo == 'li' ||
            bloque.xtipo == 'fieldset' ||
            bloque.xtipo == 'accordion'
          )?_c('h3',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(bloque.xtitulo)+" ")]):_vm._e(),(bloque.xtipo == 'p')?_c('p',[_vm._v(" "+_vm._s(bloque.xcontenido)+" "),(bloque.xto && bloque.xto.length > 0)?_c('a',{attrs:{"href":bloque.xto,"target":"_blank"}},[_vm._v("Saber más")]):_vm._e()]):_vm._e(),(bloque.xtipo == 'li')?_c('ul',{staticClass:"lista"},_vm._l((bloque.lis),function(li,index){return _c('li',{key:li,staticClass:"lista-item"},[_vm._v(" "+_vm._s(li)+" "),(bloque.links[index] && bloque.links[index].length > 0)?_c('a',{attrs:{"href":bloque.links[index],"target":"_blank"}},[_vm._v("Saber más")]):_vm._e()])}),0):_vm._e(),(bloque.xtipo == 'accordion')?_c('Accordion',_vm._l((bloque.accordionstitulos),function(titulo,index){return _c('AccordionTab',{key:index,attrs:{"header":titulo}},[_vm._v(" "+_vm._s(bloque.accordionstextos[index])+" "),_c('br'),(
                bloque.accordionlinks &&
                bloque.accordionlinks[index] &&
                bloque.accordionlinks[index].length > 0
              )?_c('a',{attrs:{"href":bloque.accordionlinks[index],"target":"_blank"}},[_vm._v("Saber más")]):_vm._e()])}),1):_vm._e(),(bloque.xtipo == 'fieldset')?_c('div',_vm._l((bloque.fieldsetstitulos),function(titulo,index){return _c('Fieldset',{key:index,attrs:{"toggleable":true,"collapsed":true},scopedSlots:_vm._u([{key:"legend",fn:function(){return [_c('span',{staticClass:"text-left text-xl"},[_vm._v(" "+_vm._s(titulo)+" ")])]},proxy:true}],null,true)},[_c('p',{staticClass:"text-lg"},[_vm._v(_vm._s(bloque.fieldsetstextos[index]))]),_c('br'),(
                bloque.fieldsetslinks[index] &&
                bloque.fieldsetslinks[index].length > 0
              )?_c('a',{attrs:{"href":bloque.fieldsetslinks[index],"target":"_blank"}},[_vm._v("Saber más")]):_vm._e()])}),1):_vm._e(),(bloque.xtipo == 'img')?_c('div',[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(bloque.xtitulo),expression:"bloque.xtitulo"}],attrs:{"href":bloque.xto}},[_c('img',{staticClass:"w-100",attrs:{"src":bloque.ximg,"alt":bloque.xtitulo}})])]):_vm._e()],1)}),_c('div',{staticClass:"row row-cols-1 row-cols-md-2 justify-content-center g-4 text-left"},_vm._l((_vm.bloquesCard),function(card,index){return _c('div',{key:index,staticClass:"col card-group"},[(card.xcategoria && card.xcategoria!='undefined')?_c('h3',{staticClass:"w-100"},[_vm._v(_vm._s(card.xcategoria))]):_vm._e(),_c('div',{staticClass:"card ml-auto mr-auto",staticStyle:{"max-width":"450px"}},[_c('img',{staticClass:"card-img-top",attrs:{"src":card.ximg,"alt":"..."}}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-title text-center"},[_c('h3',[_vm._v(_vm._s(card.xtitulo))]),_c('h4',{staticClass:"fs-6 text-primary"},[_vm._v(" "+_vm._s(card.cardsSubtitulo[0])+" ")]),(
                    card.cardsKitDigitalPrecioSin &&
                    card.cardsKitDigitalPrecioCon
                  )?_c('div',{staticClass:"text-left"},[_c('br'),_c('p',{staticClass:"fs-6"},[_c('span',[_vm._v("Precio de la implantación sin subvención ")]),_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(card.cardsKitDigitalPrecioSin[0]))])]),_c('p',{staticClass:"text-primary"},[_c('span',{staticClass:"fs-1"},[_vm._v(_vm._s(card.cardsKitDigitalPrecioCon[0]))]),_c('span',{staticClass:"fs-5"},[_vm._v(" Precio con subvención")])])]):_vm._e()]),_c('div',{staticClass:"card-text"},[_c('p',[_vm._v(_vm._s(card.cardsParrafo[0]))]),(card.cardsLista[0])?_c('ul',{staticClass:"lista"},_vm._l((card.cardsLista[0].split('||')),function(li,i){return _c('li',{key:li + i,staticClass:"lista-item"},[_vm._v(" "+_vm._s(li)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-end"},[(card.xto)?_c('router-link',{staticClass:"mb-2",attrs:{"to":card.xto}},[_c('Button',{staticClass:"l-auto",attrs:{"label":"Saber más"}})],1):_vm._e()],1),_c('div',{staticClass:"card-footer mt-auto text-muted"},[_c('p',[_vm._v(_vm._s(card.cardsFooter[0]))])])])])])}),0),_c('coDescargables')],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }