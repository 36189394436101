<template lang="html">
  <div class="co-hero">
    <div class="grid grid-nogutter surface-0 text-800 align-items-center justify-content-center">
      <div
        class="col-12 col-lg-6 col-xl-4 pb-6 md:p-6 text-left md:text-left flex align-items-center flex-order-2 md:flex-order-1"
      >
        <section>
          <span class="block text-3xl md:text-6xl font-bold mb-1">
            {{ servicio.xtitulo }}
          </span>
          <div class="text-3xl md:text-6xl text-primary font-bold mb-3">
            {{ servicio.xtitulo2 }}
          </div>
          <p class="mt-0 mb-4 text-700 text-xl line-height-3">
            {{ servicio.xdescripcion }}
          </p>
          <!--<vs-button
            v-if="servicio.xto"
            label="Saber más"
            class="mr-3 p-button-raised"
            :to="servicio.xto"
            size="large"
            floating
          >
            <span>Saber más</span>
          </vs-button>-->
        </section>
      </div>
      <div
        v-if="!servicio.xvideo"
        class="col-12 col-lg-6 col-xl-7 overflow-hidden flex-order-1 md:flex-order-2"
      >
        <img
          :src="servicio.ximg"
          alt="Image hero"
          class="md:ml-auto block md:h-full hero-image"
          
        />
      </div>
  
      <video
        v-if="servicio.xvideo"
        autoplay
        muted
        loop
        id="myVideo"
        class="mt-7 mt-lg-0 order-1 order-lg-2 col-12 col-lg-6 col-xl-8 ml-auto mr-auto overflow-hidden flex-order-1 md:flex-order-2"
        style="clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 20% 52%)"
      >
        <source :src="servicio.xvideo" type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>
  <!--<div
    ref="heroService"
    :id="servicio.xtitulo"
    class="co-hero-servicio d-flex flex-wrap justify-content-center align-content-center"
  >
    <h1 class="col-12" v-if="servicio.xtitulo">
      {{ servicio.xtitulo.toLowerCase() }}
    </h1>
  </div>-->
</template>

<script lang="js">
  export default  {
    name: 'co-hero-servicio',
    props:{
      servicio:{
        default:{
          xtitulo: 'Sin titulo'
        }
      }
    },
    updated () {
      if(this.$refs.heroService){
        this.$refs.heroService.style.background = `
        linear-gradient(
        45deg,
        rgba(255, 212, 104, 0.4) 0%,
        rgba(255, 255, 255, 0.5) 31%,
        rgba(108, 191, 194, 0.5) 100%
      )
      0 0 / 1000% no-repeat,
    url(${this.servicio.ximg}) 0 0 / cover no-repeat
        `
        }
    },
    data () {
      return {

      }
    },
    methods: {
    },
    computed: {

    },
    mounted () {
      console.log(this.servicio)
    }
}
</script>

<style scoped lang="css">
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300);
* {
  margin: 0;
  padding: 0;
}
.co-hero-servicio > h1 {
  width: fit-content;
  padding: 1rem;
  background-color: #6868684d;
}
.co-hero-servicio {
  height: 25vh;
  background: linear-gradient(
        45deg,
        rgba(255, 212, 104, 0.4) 0%,
        rgba(255, 255, 255, 0.5) 31%,
        rgba(108, 191, 194, 0.5) 100%
      )
      0 0 / 1000% no-repeat,
    url(https://picsum.photos/g/2000/1200?image=443) 0 0 / cover no-repeat;
  -webkit-animation: gradientAnimation 40s ease infinite;
  animation: gradientAnimation 40s ease infinite;
}
@-webkit-keyframes gradientAnimation {
  0% {
    background-position: 0% 30%, 0 0;
  }
  50% {
    background-position: 100% 70%, 0 0;
  }
  100% {
    background-position: 0% 30%, 0 0;
  }
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 30%, 0 0;
  }
  50% {
    background-position: 100% 70%, 0 0;
  }
  100% {
    background-position: 0% 30%, 0 0;
  }
}
h1 {
  color: #fff;
  font: normal 600 40px/1 "Open Sans", sans-serif;
  text-align: center;
  text-transform: capitalize;
}
h1 span {
  display: block;
  margin-top: 1em;
  font-size: 20px;
  font-weight: 300;
  text-transform: initial;
}
</style>
