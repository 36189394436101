<template lang="html">
  <section class="servicios-vue text-lg">
    <coHeroServicio :servicio="servicio" class="p-2 p-md-0"/>
    <div
      class="servicios-wrapper align-items-start d-flex flex-wrap ml-2 md:mx-6 xl:mx-8 mt-5 pt-5 pb-5 mb-5"
    >
      <div class="col-12 col-xl-4 servicios order-xl-1 order-2">
        <div class="py-5 px-5 servicios-menu pl-5">
          <h2 class="mb-4">SERVICIOS</h2>
          <span
            v-for="(serv, index) in todosServicios"
            :key="index + serv + index"
            @click="irA(serv.xto)"
            class="py-3 px-2 servicios-menu-links text-md"
            >{{ serv.xtitulo }} &ensp; &ensp; ⇰</span
          >
          <coConozca
            class="mt-4 -ml-5"
            style="margin-left: -1.7rem; margin-right: -1.7rem"
            origen1="/servicios"
            :origen2="'/' + servicio.xservicioid"
          />
        </div>
      </div>
      <ScrollPanel
        class="col-12 col-xl-8 lg:pl-5 d-flex flex-column order-xl-2 order-1 custombar2"
      >
        <div
          class="d-flex flex-column text-left mb-5"
          v-for="bloque in bloquesSinCards"
          :key="bloque.xid"
        >
          <h3
            v-if="
              bloque.xtipo == 'p' ||
              bloque.xtipo == 'li' ||
              bloque.xtipo == 'fieldset' ||
              bloque.xtipo == 'accordion'
            "
            class="mb-4"
          >
            {{ bloque.xtitulo }}
          </h3>
          <p v-if="bloque.xtipo == 'p'">
            {{ bloque.xcontenido }}
            <a
              v-if="bloque.xto && bloque.xto.length > 0"
              :href="bloque.xto"
              target="_blank"
              >Saber más</a
            >
          </p>
          <ul class="lista" v-if="bloque.xtipo == 'li'">
            <li class="lista-item" v-for="(li, index) in bloque.lis" :key="li">
              {{ li }}
              <a
                v-if="bloque.links[index] && bloque.links[index].length > 0"
                :href="bloque.links[index]"
                target="_blank"
                >Saber más</a
              >
            </li>
          </ul>
          <Accordion v-if="bloque.xtipo == 'accordion'">
            <AccordionTab
              v-for="(titulo, index) in bloque.accordionstitulos"
              :key="index"
              :header="titulo"
            >
              {{ bloque.accordionstextos[index] }}
              <br />
              <a
                v-if="
                  bloque.accordionlinks &&
                  bloque.accordionlinks[index] &&
                  bloque.accordionlinks[index].length > 0
                "
                :href="bloque.accordionlinks[index]"
                target="_blank"
                >Saber más</a
              >
            </AccordionTab>
          </Accordion>
          <div v-if="bloque.xtipo == 'fieldset'">
            <Fieldset
              v-for="(titulo, index) in bloque.fieldsetstitulos"
              :key="index"
              :toggleable="true"
              :collapsed="true"
            >
              <template #legend>
                <span class="text-left text-xl">
                  {{ titulo }}
                </span>
              </template>
              <p class="text-lg">{{ bloque.fieldsetstextos[index] }}</p>
              <br />
              <a
                v-if="
                  bloque.fieldsetslinks[index] &&
                  bloque.fieldsetslinks[index].length > 0
                "
                :href="bloque.fieldsetslinks[index]"
                target="_blank"
                >Saber más</a
              >
            </Fieldset>
          </div>
          <div v-if="bloque.xtipo == 'img'">
            <a :href="bloque.xto" v-tooltip="bloque.xtitulo">
              <img :src="bloque.ximg" :alt="bloque.xtitulo" class="w-100" />
            </a>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-md-2 justify-content-center g-4 text-left">
          <div
            class="col card-group"
            v-for="(card, index) in bloquesCard"
            :key="index"
          >
            <h3 class ="w-100" v-if="card.xcategoria && card.xcategoria!='undefined'">{{card.xcategoria}}</h3>
            <div class="card ml-auto mr-auto" style="max-width: 450px">
              <img :src="card.ximg" class="card-img-top" alt="..." />
              <div class="card-body">
                <div class="card-title text-center">
                  <h3>{{ card.xtitulo }}</h3>
                  <h4 class="fs-6 text-primary">
                    {{ card.cardsSubtitulo[0] }}
                  </h4>
                  <div
                    v-if="
                      card.cardsKitDigitalPrecioSin &&
                      card.cardsKitDigitalPrecioCon
                    "
                    class="text-left"
                  >
                    <br />
                    <p class="fs-6">
                      <span>Precio de la implantación sin subvención </span>
                      <span style="text-decoration: line-through">{{
                        card.cardsKitDigitalPrecioSin[0]
                      }}</span>
                    </p>
                    <p class="text-primary">
                      <span class="fs-1">{{
                        card.cardsKitDigitalPrecioCon[0]
                      }}</span>
                      <span class="fs-5"> Precio con subvención</span>
                    </p>
                  </div>
                </div>
                <div class="card-text">
                  <p>{{ card.cardsParrafo[0] }}</p>
                  <ul class="lista" v-if="card.cardsLista[0]">
                    <li
                      class="lista-item"
                      v-for="(li, i) in card.cardsLista[0].split('||')"
                      :key="li + i"
                    >
                      {{ li }}
                    </li>
                  </ul>
                </div>
                <div class="d-flex justify-content-end">
                  <router-link v-if="card.xto" :to="card.xto" class="mb-2">
                    <Button label="Saber más" class="l-auto"></Button>
                  </router-link>
                </div>

                <div class="card-footer mt-auto text-muted">
                  <p>{{ card.cardsFooter[0] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <coDescargables />
      </ScrollPanel>
    </div>
  </section>
</template>

<script lang="js">
import coHeroServicio from '../components/Servicios/coHeroServicio.vue'
import coDescargables from '../components/Servicios/coDescargables.vue'
import coConozca from '../components/coConozca.vue'
import ViewService from '../services/view.service'
import API_URL from '../backend'
  export default  {
    name: 'servicios-vue',
    props: [],
    components: {
      coHeroServicio: coHeroServicio,
      coConozca: coConozca,
      coDescargables:coDescargables
    },
    mounted () {
      this.cargarDatos()
      this.controlActualizarVistaRuta = this.$route.fullPath;
    },
    data () {
      return {
        loader:null,
        servicio:{},
        bloques:[],
        todosServicios:{},
        controlActualizarVistaRuta:'',
      }
    },
    methods: {
      quitarLogo (){
        this.loader.close()
      },
      cargarDatos(){
        ViewService.getDatosWeb().then((response)=>{
          const logo = response.data[0].xlogo;
          this.loader = this.$vs.loading({
          type: "square",
          color: "#f8b505",
        });
        setTimeout(() => {
          let loaderDIV = document.getElementsByClassName(
            "vs-loading__load__animation"
          )[0];
          loaderDIV.innerHTML = "";
          const imgLogo = document.createElement("img");
          imgLogo.src = API_URL+logo;
          imgLogo.style = "width:200px; z-index: 99999999; border-radius:10px; transition: width 0.4s ease-in-out";

          loaderDIV.addEventListener("mouseover", (e) => {
            e.target.style.width="300px";
          })
          loaderDIV.addEventListener("mouseout", (e) => {
            e.target.style.width="200px";
          })
          loaderDIV.appendChild(imgLogo);
        }, 5);
        });

        if(this.$route.params.enlace == "servicios"){
          ViewService.getServicios().then((response)=>{
            let servicios = response.data;
            this.todosServicios = response.data;
            let existe = servicios.find((serv)=> serv.xservicioid == this.$route.params.id);
            if(!existe){
              setTimeout(this.quitarLogo, 500);
              this.$router.push('/404');
            }else{
              this.servicio = existe;
              this.servicio.ximg = API_URL+this.servicio.ximg;
              ViewService.getServiciosBlocks(this.servicio.xservicioid).then((response)=>{
                this.bloques = response.data;
                this.bloques.map((bloque)=>{
                  bloque.ximg = API_URL+bloque.ximg
                  if(bloque.xtipo == 'li'){
                    bloque.links = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[1]);
                    bloque.lis = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[0]);
                  }
                  else if(bloque.xtipo == 'accordion'){
                    bloque.accordionlinks = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[2]);

                    bloque.accordionstextos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[1])
                    bloque.accordionstitulos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[0])
                  }else if(bloque.xtipo == 'fieldset'){
                    bloque.fieldsetslinks = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[2]);
                    bloque.fieldsetstextos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[1]);
                    //console.log(bloque.fieldsetstextos)
                    bloque.fieldsetstitulos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[0]);
                    //ºconsole.log(bloque.fieldsetstitulos)
                  }else if(bloque.xtipo == 'card'){
                    bloque.cardsSubtitulo = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[0]);
                    bloque.cardsKitDigitalPrecioSin = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[1]);
                    bloque.cardsKitDigitalPrecioCon = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[2]);
                    bloque.cardsParrafo = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[3]);
                    bloque.cardsLista = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[4]);
                    bloque.cardsFooter = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[5]);
                  }
                })
                setTimeout(this.quitarLogo, 500);
              }).catch(()=>{this.$router.push('/404'); setTimeout(this.quitarLogo, 500);})
            }
          }).catch((error)=>{
            console.error(error)
            setTimeout(this.quitarLogo, 500);
            this.$router.push('/404')
          });
        } else if (this.$route.params.enlace == "perfiles"){
          ViewService.getPerfiles().then((response)=>{
            let servicios = response.data;
            this.todosServicios = response.data;
            let existe = servicios.find((serv)=> serv.xperfilid == this.$route.params.id);
            if(!existe){
              setTimeout(this.quitarLogo, 500);
              this.$router.push('/404');
            }else{
              this.servicio = existe;
              this.servicio.ximg = API_URL+this.servicio.ximg;
              ViewService.getPerfilesBlocks(this.servicio.xperfilid).then((response)=>{

                this.bloques = response.data;
                this.bloques.map((bloque)=>{
                  if(bloque.xtipo == 'li'){
                    bloque.links = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[1]);
                    bloque.lis = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[0]);
                  }
                  else if(bloque.xtipo == 'accordion'){
                    bloque.accordionstextos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[1])
                    bloque.accordionstitulos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[0])
                  }else if(bloque.xtipo == 'fieldset'){
                    bloque.fieldsetstextos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[1])
                    //console.log(bloque.fieldsetstextos)
                    bloque.fieldsetstitulos = bloque.xcontenido.split('&&').map((str)=>str = str.split("##")[0])
                    //ºconsole.log(bloque.fieldsetstitulos)
                  }
                })
                setTimeout(this.quitarLogo, 500);
              }).catch((error)=>{
                console.log(error)
                this.$router.push('/404')
                setTimeout(this.quitarLogo, 500);
              })
            }
          }).catch((error)=>{
            console.error(error)
            this.$router.push('/404')
            setTimeout(this.quitarLogo, 500);
          });
        }else{this.$router.push('/404')}
      },
      irA(to){
        let id = to.split('/')[to.split('/').length - 1]
        this.$route.params.id = id ;
        this.cargarDatos()
        this.$router.push(to);
      }
    },
    computed: {
      bloquesSinCards(){
        return this.bloques.filter((bloque)=>{
          return bloque.xtipo != 'card'
        })
      },
      bloquesCard(){
        return this.bloques.filter((bloque)=>{
          return bloque.xtipo == 'card'
        })
      }
    }
}
</script>

<style lang="scss">
.servicios-vue {
}
.servicios-wrapper {
  /* max-height: 90vh; */
}
.servicios {
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  @media (min-width: 1200px) {
    position: sticky;
    top: 80px;
    bottom: 80px;
  }

  &-menu {
    background-color: #e9ecefcb;
    text-align: left;
    h2 {
      margin-top: 1.8rem;
      margin-bottom: 1.5rem;
      font-size: 20px;
      font-weight: 700;
      text-align: left;
    }
    .telf {
      color: #f8b305;
      font-weight: 600;
      text-decoration: none;
      transition: all 0.5 var(--ease-out-expo);
      &:hover {
        color: #c9991f;
      }
    }
    &-links {
      padding: 10px 3px;

      text-align: left;
      display: block;
      text-decoration: none;
      color: black;
      font-weight: 500;
      &::before,
      &::after {
        position: relative;
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        top: 10px;
        background: #f8b305;
        transition: 1.1s var(--ease-out-expo);
      }
      &::before {
        top: 40px;
        transform: scaleX(0);
        transform-origin: left;
      }

      &::after {
        transform-origin: right;
        transition-delay: 0.25s;
      }

      &:hover {
        color: #f8b305;
        transition: all 0.3s var(--ease-out-expo);
        font-weight: 600;
        &::before {
          transform: scaleX(1);
          transition-delay: 0.25s;
        }

        &::after {
          transform: scaleX(0);
          transition-delay: 0s;
        }
      }
    }
  }
}

.custombar2 {
  .p-scrollpanel-wrapper {
    border-right: 9px solid var(--layer-1);
    border-bottom: 9px solid var(--layer-1);
  }

  .p-scrollpanel-bar {
    background-color: var(--layer-2);
    border-radius: 0;
    opacity: 1;
    transition: background-color 0.2s;
  }
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b3057a !important;
}
.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b3057a !important;
}
.lista {
  list-style: none;
  &-item::before {
    content: "◉";
    padding-right: 8px;
    color: #f8b305;
  }
}
</style>
